<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">Companies</a></li>
    </ol>
    <h1 class="page-header">COMPANIES</h1>
    <panel noButton="true" title="COMPANIES" variant="panel-default">
      <loading v-if="loading" />
      <template v-else>
        <div v-if="$can.view($store.getters.getAuthUser, 'list-companies')" class="row mb-3">
          <div class="input-group col-md-4">
            <input v-model="search" class="form-control py-2 border-right-0 border" placeholder="Search By Name..."
                   type="search">
            <span class="input-group-append">
            <button class="btn btn-outline-secondary border-left-0 border" type="button">
              <i class="fa fa-search" />
            </button>
          </span>
          </div>
          <div class="input-group col-md-4">
            <v-select v-model="group_id" :options="groups" :reduce="g => g.id" class="w-75" label="name"
                      placeholder="Filter By Pricing Group" />
          </div>
          <div class="input-group col-md-4">
            <div class="d-flex justify-content-end w-100">
              <v-select v-model="pagination.per_page" :options="pages" label="per_page"
                        placeholder="Per Page" />
              <button v-if="$can.view($store.getters.getAuthUser, 'view-companies')"
                      class="btn btn-secondary ml-2 pull-right" @click="exportCompanies">
                <i class="fa fa-download"></i> Export
              </button>
            </div>
          </div>

        </div>
        <table v-if="companies.length" class="table table-striped table-responsive-sm">
          <thead>
          <tr v-if="$store.getters.getAuthUser.role === 'agent'">
            <th>#</th>
            <th>Name</th>
            <th>CPR</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Physical Address</th>
            <th>Postal Code</th>
            <th>Postal Address</th>
            <th>County</th>
            <th>Actions</th>
          </tr>
          <tr v-else>
            <th>#</th>
            <th>Name</th>
            <th>CPR</th>
            <th>Email</th>
            <th>Phone</th>
            <th>Physical Address</th>
<!--            <th>Postal Code</th>-->
            <th>Approval</th>
            <th>Payment</th>
<!--            <th>Created</th>-->
            <th>Updated</th>
            <template v-if="$can.view($store.getters.getAuthUser, 'view-companies')">
              <th>Actions</th>
            </template>
          </tr>
          </thead>
          <tbody>
          <template v-if="$store.getters.getAuthUser.role === 'agent'">
            <tr
                v-for="(company, i) in companies"
                :key="i"
            >
              <td>{{ i + pagination.from }}</td>
              <td>{{ company.name }}</td>
              <td>{{ company.crp }}</td>
              <td>{{ company.postal_address }}</td>
              <td>{{ company.postal_code }}</td>
              <td>{{ company.county }}</td>
              <td>
                <button
                    class="btn btn-sm btn-info"
                    type="button"
                    @click.prevent="editModal(company)"
                >
                  <i
                      class="fa fa-edit"
                  /> Edit
                </button>
                <button
                    class="btn btn-sm ml-1 btn-danger"
                    type="button"
                    @click.prevent="deleteCompany(company)"
                >
                  <i
                      class="fa fa-trash"
                  /> Delete
                </button>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="(company, i) in companies" :key="i">
              <td>{{ i + pagination.from }}</td>
              <td>{{ company.name }}</td>
              <td>{{ company.crp }}</td>
              <td>{{ company.email }}</td>
              <td>{{ company.phone_number }}</td>
              <td>{{ company.physical_address }}</td>
<!--              <td>{{ company.postal_code_id }}</td>-->
              <td><span class="badge badge-pill" :class="getStatusClass(company.approval_status)">{{ company.approval_status }}</span></td>
              <td><span class="badge badge-pill" :class="getPaidClass(company.paid)">{{ company.paid ? 'Paid' : 'Unpaid' }}</span></td>
<!--              <td>{{ company.created_at }}</td>-->
              <td>{{ company.updated_at }}</td>
              <template v-if="$can.view($store.getters.getAuthUser, 'view-companies')">
                <td>
                  <span class="comp">
                    <router-link :to="{name: 'companies.show', params: { id: company.company_unique_id }}"
                                 class="btn btn-sm btn-warning">show</router-link>
                  </span>
                </td>
              </template>
            </tr>
          </template>
          </tbody>
        </table>
        <div v-else class="alert alert-warning">
          <p>There are no companies</p>
        </div>
        <pagination v-if="pagination.last_page > 1" :offset="5" :pagination="pagination" @paginate="fetch" />
      </template>
      <modal v-if="company" :show-modal="showModal" @close="showModal = false">
        <template v-slot:header>
          <h5>Edit Company</h5>
        </template>
        <form @submit.prevent="validateForm">
          <div class="container">
            <div class="row">
              <div :class="{ 'has-feedback has-error': errors.has('company_name')}" class="col-md-6 form-group">
                <label>Company Name</label>
                <input v-model="company.name" v-validate="'required'" class="form-control" name="company_name"
                       type="text">
                <small v-if="errors.has('company_name')" class="help-block">
                  {{ errors.first('company_name') }}
                </small>
              </div>
              <div :class="{ 'has-feedback has-error': errors.has('registration_number')}" class="col-md-6 form-group">
                <label>Registration Number(CPR)</label>
                <input v-model="company.crp" v-validate="'required'" class="form-control" name="registration_number"
                       placeholder="e.g CPR/2001/2650" type="text">
                <small v-if="errors.has('registration_number')" class="help-block">
                  {{ errors.first('registration_number') }}
                </small>
              </div>
              <div :class="{ 'has-feedback has-error': errors.has('postal_address')}" class="col-md-6 form-group">
                <label>Company Postal Address</label>
                <input v-model="company.postal_address" v-validate="'required|numeric'" class="form-control"
                       name="postal_address" placeholder="eg 123" type="text">
                <small v-if="errors.has('postal_address')" class="help-block">
                  {{ errors.first('postal_address') }}
                </small>
              </div>
              <div :class="{ 'has-feedback has-error': errors.has('postal_code')}" class="col-md-6 form-group">
                <label>Postal Code</label>
                <v-select v-model="company.postal_code_id" v-validate="'required'" :options="postal_codes"
                          :reduce="c => c.id" label="code" name="postal_code" placeholder="Postal Code" />
                <small v-if="errors.has('postal_code')" class="help-block">
                  {{ errors.first('postal_code') }}
                </small>
              </div>
              <div class="col-md-6 form-group">
                <label>County</label>
                <input v-model="$store.getters.getCounty" class="form-control" placeholder="County" readonly
                       type="text">
              </div>
              <div class="col-md-12 form-group">
                <button class="btn btn-primary pull-right">
                  Submit
                </button>
              </div>
            </div>
          </div>
        </form>
      </modal>
    </panel>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {

  data() {
    return {
      search: '',
      companies: [],
      group_id: null,
      loading: false,
      showModal: false,
      company: {
        name: '',
        crp: '',
        postal_address: '',
        postal_code_id: null
      },
      pagination: {
        'per_page': 15,
        'current_page': 1,
        'last_page': 1,
        'from': 1
      }
    }
  },
  computed: {
    ...mapGetters({
      postal_codes: 'getPostalCodes',
      groups: 'getGroups',
      pages: 'getPages'
    }),
  },
  watch: {
    search(o, n) {
      if (!n && !o) return;
      this.fetchEntriesDebounced();
    },
    group_id(o, n) {
      if (!n && !o) return;
      this.fetchEntriesDebounced();
    },
    'pagination.per_page'() {
      this.fetch();
    },
    'company.postal_code_id'(n) {
      this.$store.dispatch('setCounty', n);
    }
  },
  mounted() {
    this.fetch();
  },

  methods: {
    getStatusClass(status){
      return {
        "badge-success": status === 'approved',
        'badge-danger': status === 'rejected',
        'badge-secondary': status === 'pending',
      }
    },
    getPaidClass(status){
      return status ? "badge-success" : "badge-warning";
    },
    exportCompanies() {
      this.loading = true;
      this.$axios({
        url: '/api/v1/companies?export=xlsx',
        method: 'GET',
        responseType: 'arraybuffer',
      }).then((response) => {
        this.loading = false;
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', 'companies_export.xlsx');
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    fetch() {
      this.$axios.get('/api/v1/companies', {
        params: {
          per_page:this.pagination.per_page,
          page: this.pagination.current_page,
          search: this.search,
          group_id: this.group_id
        }
      }).then(response => {
        this.loading = false;
        if (!response.data.data) return;
        this.companies = response.data.data;
        this.pagination = this.pagination = (({total, per_page, current_page, last_page, from, to}) => ({
          total,
          per_page,
          current_page,
          last_page,
          from,
          to
        }))(response.data);
      });
      if (!this.groups.length) {
        this.loading = true;
        this.$store.dispatch("fetchGroups").then(() => {
          this.loading = false;
        });
      }
    },
    fetchEntriesDebounced() {
      clearTimeout(this._timerId);
      this._timerId = setTimeout(() => {
        this.fetch()
      }, 2000)
    },
    editModal(company) {
      this.company = company;
      if (this.postal_codes.length) {
        this.showModal = true;
        return;
      }
      this.loading = true;
      this.$store.dispatch("fetchPostalCodes").then(() => {
        this.loading = false;
        this.showModal = true;
      });
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.update();
        }
      });
    },
    update() {
      this.company.company_id = this.company.company_unique_id;
      const company = (({company_id, name, crp, postal_address, postal_code_id}) =>
          ({company_id, name, crp, postal_address, postal_code_id}))(this.company);

      this.$axios.put('/api/v1/agent/companies', company).then(() => {
        this.$toastr.s("Company updated successfully");
        this.showModal = false;
        this.fetch();
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    },
    deleteCompany(company) {
      this.$swal({
        title: 'Are you sure?',
        text: "Are you sure you want to delete the company " + company.name + " ?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.delete('/api/v1/agent/companies', {data: {'company_id': company.company_unique_id}}).then(() => {
            this.fetch();
            this.$toastr.s('Company deleted');
          }).catch(error => {
            this.$toastr.w(error.response.data.error);
          });
        }
      })
    },
  }
}
</script>