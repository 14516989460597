<template>
  <panel noButton="true" title="ONBOARD A COMPANY">
    <div v-html="$error.handle(error)" />
    <form
        class="row"
        @submit.prevent="validateForm"
        id="create-company"
    >
      <div
          class="form-group col-md-4"
          :class="{ 'has-feedback has-error': errors.has('company_name')}"
      >
        <label>Company Name*</label>
        <input
            v-model="name"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="company_name"
        >
        <small
            v-if="errors.has('company_name')"
            class="help-block"
        >
          {{ errors.first('company_name') }}
        </small>
      </div>
      <div class="col-md-4">
        <div
            class="form-group"
            :class="{ 'has-feedback has-error': errors.has('registration_number')}"
        >
          <label>Registration Number*</label>
          <input
              v-model="crp"
              v-validate="'required'"
              type="text"
              class="form-control"
              placeholder="e.g CPR/2001/2650"
              name="registration_number"
          >
          <small
              v-if="errors.has('registration_number')"
              class="help-block"
          >
            {{ errors.first('registration_number') }}
          </small>
        </div>
      </div>
      <template v-if="current_user.role !== 'agent'">
        <div
            class="form-group col-md-4"
            :class="{ 'has-feedback has-error': errors.has('email')}"
        >
          <label>Company Email*</label>
          <input
              v-model="email"
              v-validate="'required|email'"
              type="email"
              class="form-control"
              name="email"
              autocomplete="email"
          >
          <small
              v-if="errors.has('email')"
              class="help-block"
          >
            {{ errors.first('email') }}
          </small>
        </div>
        <div
            class="form-group col-md-4"
            :class="{ 'has-feedback has-error': errors.has('kra_pin')}"
        >
          <label>Company KRA PIN*</label>
          <input
              v-model="kra_pin"
              v-validate="'required'"
              type="text"
              class="form-control"
              name="kra_pin"
          >
          <small
              v-if="errors.has('kra_pin')"
              class="help-block"
          >
            {{ errors.first('kra_pin') }}
          </small>
        </div>
        <div
            class="form-group col-md-4"
            :class="{ 'has-feedback has-error': errors.has('phone')}"
        >
          <label>Phone Number*</label>
          <phone-number
              v-model="phone_number"
              v-validate="'required'"
              name="phone"
          />
          <small
              v-if="errors.has('phone')"
              class="help-block"
          >
            {{ errors.first('phone') }}
          </small>
        </div>
      </template>

      <div
          class="form-group col-md-4"
          :class="{ 'has-feedback has-error': errors.has('postal_address')}"
      >
        <label>Postal Address*</label>
        <input
            v-model="postal_address"
            v-validate="'required|numeric'"
            type="text"
            placeholder="eg 123"
            class="form-control"
            name="postal_address"
            autocomplete="address-line1"
        >
        <small
            v-if="errors.has('postal_address')"
            class="help-block"
        >
          {{ errors.first('postal_address') }}
        </small>
      </div>

      <div
          class="form-group col-md-4"
          :class="{ 'has-feedback has-error': errors.has('postal_code')}"
      >
        <label>Postal Code*</label>
        <v-select
            v-model="postal_code"
            v-validate="'required'"
            :options="postal_codes"
            label="code"
            name="postal_code"
            autocomplete="postal-code"
            placeholder="Postal Code"
        />
        <small
            v-if="errors.has('postal_code')"
            class="help-block"
        >
          {{ errors.first('postal_code') }}
        </small>
      </div>

      <div
          v-if="postal_code"
          class="form-group col-md-4"
      >
        <label>Postal Name*</label>
        <input
            v-model="postal_code.name"
            type="text"
            class="form-control"
            readonly
        >
      </div>

      <div
          v-if="current_user.role !== 'agent'"
          class="form-group col-md-4"
          :class="{ 'has-feedback has-error': errors.has('physical_address')}"
      >
        <label>Physical Address*</label>
        <input
            v-model="physical_address"
            v-validate="'required'"
            type="text"
            class="form-control"
            name="physical_address"
            autocomplete="address-level1"
        >
        <small
            v-if="errors.has('physical_address')"
            class="help-block"
        >
          {{ errors.first('physical_address') }}
        </small>
      </div>
      <div
          v-if="current_user.role !== 'agent'"
          class="form-group col-md-4"
      >
        <label>Relationship Manager
          <small class="text-muted">(Optional)</small>
        </label>
        <v-select
            v-model="relationship_manager"
            :options="rms"
            name="relationship_manager"
            label="fullname"
            placeholder="Relationship Manager"
        />
      </div>
      <div v-if="current_user.role !== 'agent'"
           class="col-md-4 form-group">
        <label>Company Registration Document</label>
        <input
            ref="registration_document"
            v-validate="'required'"
            name="registration_document"
            type="file"
            required
            class="form-control"
        >
        <small
            v-if="errors.has('form.registration_document')"
            class="help-block"
        >
          {{ errors.first('form.registration_document') }}
        </small>
      </div>
      <div
          v-if="$can.view($store.getters.getAuthUser, 'skip-aml')"
          class="form-group col-md-4"
      >
        <div class="form-check">
          <input
              v-model="skip_aml"
              class="form-check-input"
              name="skip_aml"
              type="checkbox"
              value="0"
          >
          <label class="form-check-label" for="skip_aml">Skip Aml Check
            <small class="text-muted">(Optional)</small>
          </label>

        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-primary pull-right">
          Submit
        </button>
      </div>
    </form>
  </panel>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {
      name: '',
      crp: '',
      kra_pin: '',
      email: '',
      phone_number: '',
      physical_address: '',
      postal_address: '',
      relationship_manager: null,
      postal_code: null,
      error: '',
      skip_aml: false,
      registration_document:'',
    }
  },
  computed: {
    ...mapGetters({
      current_user: 'getAuthUser',
      postal_codes: 'getPostalCodes',
      rms: 'getRelationshipManagers'
    }),
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      if (!this.postal_codes.length) {
        this.$store.dispatch("fetchPostalCodes")
      }
      if (!this.rms.length) {
        this.$store.dispatch("fetchRelationshipManagers");
      }
    },
    validateForm() {
      return this.$validator.validateAll().then(result => {
        if (result) {
          this.save();
        }
      });
    },
    agentSave() {
      this.$axios.post('/api/v1/agent/companies', {
        'name': this.name,
        'postal_address': this.postal_address,
        'postal_code_id': this.postal_code.id,
        'crp': this.crp
      }).then(() => {
        this.$router.push({name: 'companies.index'});
      }).catch(err => {
        if (err.response) {
          this.error = err.response;
        }
      });
    },
    save() {
      this.$form.load('create-company')
      
      if (this.$store.getters.getAuthUser.role === 'agent') {
        this.agentSave();
        return;
      }
      const fd = new FormData();
      fd.append('registration_document', this.$refs.registration_document.files[0]);
      fd.append('name', this.name);
      fd.append('crp', this.crp);
      fd.append('kra_pin', this.kra_pin);
      fd.append('email', this.email);
      fd.append('phone_number', this.phone_number);
      fd.append('physical_address', this.physical_address);
      fd.append('postal_address', this.postal_address);
      fd.append('postal_code_id', this.postal_code.id);
      fd.append('skip_aml', this.skip_aml ? 1 : 0);
      if (this.relationship_manager){
        fd.append('relationship_manager_id', this.relationship_manager.id);
      }

      this.$axios.post('/api/v1/companies', fd).then(resp => {
        if (resp.data.kyc_status === 1) {
          this.$router.push({name: 'companies.payment', params: {id: resp.data.company_unique_id}});
        } else {
          this.$toastr.s("Company created successfully! Finalizing registration process...");
          this.$router.push({name: 'companies.index'});
        }
      }).catch(err => {
        this.$form.validate('create-company', err.response)
      });
    }
  }
}
</script>